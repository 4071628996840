import { getMapsHelper } from './util/mapsHelper';

export const initHomeMap = () => {
	
	const mapHelper = getMapsHelper();

	mapHelper.ready()
		.then(() => {
			const theMap = mapHelper.createMap({
				element: document.querySelector('#home_map'),
				locationElementSelector: '#home_map',
				useRichmarker: true,
				markerReducer: el => {
					return {
						lat: el.getAttribute('lat'),
						lng: el.getAttribute('lng'),
						content: `
							<span>
								<img src="/static/images/icons/map_pin.svg" alt="" />
							</span>
						`
					};
				},
			});

			// eslint-disable-next-line no-undef
			google.maps.event.addListenerOnce(theMap, 'idle', () => {
				theMap.setZoom(15);
			});
		});
};