import { initMobileNav } from './header.js';
import { facilityInfoMobile, initRateFilters } from './facility.js';
import { initHomeMap } from './home';
import { contactSubmit, initContactMap } from './contact.js';

if (window.NodeList && !NodeList.prototype.forEach) {
	NodeList.prototype.forEach = Array.prototype.forEach;
}

if (!Element.prototype.matches) {
	Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}
  
if (!Element.prototype.closest) {
	Element.prototype.closest = function(s) {
		var el = this;
  
		do {
			if (el.matches(s)) return el;
			el = el.parentElement || el.parentNode;
		} while (el !== null && el.nodeType === 1);
		return null;
	};
}

Element.prototype.switchAttribute = function(attribute, val1, val2) {
	this.setAttribute(attribute, (this.getAttribute(attribute) === val1 ? val2 : val1));
};

initMobileNav();

if (document.getElementById('contact_form')) {
	contactSubmit();
}

if (document.getElementById('fac_mobile_hit')) {
	facilityInfoMobile();
}

if (document.getElementById('filter_buttons')) {
	initRateFilters();
}

if(document.querySelector('#home_map')) {
	initHomeMap();
}

if(document.querySelector('#contact_map')) {
	initContactMap();
}

//Accordion styling for Tips
if (document.getElementById('accordion_wrap')) {
	setTimeout(function(){
		document.getElementsByClassName('accord_item')[0].classList.add('active');
	}, 500);
	const helpItems = document.querySelectorAll('.accord_item');
	document.getElementById('accordion_wrap').addEventListener('click', function(e) {
		if(e.target && e.target.nodeName === "H2") {
			let el = e.target.parentElement;
			if(el.classList.contains('active')) {
				el.classList.remove('active');
			}
			else {
				helpItems.forEach(i => {
					i.classList.remove('active');
				});
				el.classList.add('active');
			}
		}
	});
}