export const facilityInfoMobile = () => {
	const mobileHit = document.getElementById('fac_mobile_hit');
	const mobileInfo = document.querySelector('#fac_header_wrap');
	console.log(mobileHit);
	mobileHit.addEventListener("click", function(){
		mobileHit.classList.toggle('active');
		mobileInfo.classList.toggle('active');
	});
};

export const initRateFilters = () => {
	const filters = document.querySelectorAll('#filter_buttons .filter');
	const rows = document.querySelectorAll('.rates_row');
	const noUnitsMsg = document.querySelector('.no_units_message');

	document.querySelector('#filter_buttons').addEventListener('click', e => {
		const clickedFilter = e.target.closest('button');
		var unitShown = 0;

		if(clickedFilter) {
			filters.forEach(i => {
				i.removeAttribute('active');
			});
			if(clickedFilter.classList.contains('filter')) {
				clickedFilter.setAttribute('active', true);
			}

			rows.forEach(i => {
				i.setAttribute('hide', true);
				if((i.getAttribute('size') === clickedFilter.getAttribute('size')) || clickedFilter.getAttribute('size') === 'all') {
					i.removeAttribute('hide');
					unitShown++;
				}
				if(unitShown === 0){
					noUnitsMsg.style.display = '';
				}else {
					noUnitsMsg.style.display = 'none';
				}
			});
		}
	});
};